import './App.css';
import { BrowserRouter as Router,Route } from "react-router-dom";
import Title from './components/Title/title';
import Home from './components/Home/home';
import Navigate from './components/Navbar/navigate';
import Footer from './components/Footer/footer';
import ParentalFitness from './components/ParentalFitnessHaven/ParentalFitness/ParentalFitness';
import JetSetter from './components/TravelerCorner/jetSetter/jetSetter';
import BusyProfessional from './components/WorkplaceWellness/busyProfessional/busyProfessional';
import ElderFitness from './components/SeniorWellnessPavilion/seniorWellness/elderFitness';
import About from './components/About/about';
import ExecutiveFitness from './components/WorkplaceWellness/ExecutiveFitness/executiveFitness';
import HealthcareHeroes from './components/WorkplaceWellness/healthcareHeroes/healthcareHeroes';
import GlobalFitness from './components/TravelerCorner/globalFitness/globalFitness';
import Articles from './components/Articles/articles';
import Pricing from './components/Pricing/pricing';
import CreateWorkout from './components/CreateWorkout/createWorkout';
import TechieWellness from './components/WorkplaceWellness/TechieWellness/techieWellness';

function App() {
  
  return (
    <Router>
      <Title />
      <Navigate />
      <Route exact path='/' component={Home} />
      <Route exact path='/articles' component={Articles} />
      <Route exact path='/busyProfessional' component={BusyProfessional} />
      <Route exact path='/executiveFitness' component={ExecutiveFitness} />
      <Route exact path='/healthcareHeroes' component={HealthcareHeroes} />
      <Route exact path='/techie-wellness' component={TechieWellness} />


      <Route exact path='/parentalFitness' component={ParentalFitness} />

      <Route path='/jetSetter'component={JetSetter} />
      <Route path='/globalFitness'component={GlobalFitness} />

      <Route exact path='/elderFitness' component={ElderFitness} />

      <Route exact path='/about' component={About} />
      <Route exact path='/pricing' component={Pricing} />
      <Route exact path='/create-workout' component={CreateWorkout} />
      
      <Footer />
    </Router>
  );
}

export default App;
