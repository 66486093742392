import Cards from "../Cards/cards";
import "./articles.css"
import React, { useEffect } from "react";




function Articles()  {
    document.title = 'LiveFithub Articles: Explore a Diverse Range of Wellness Topics';
    document.querySelector('meta[name="description"]').setAttribute(
        'content',
        "Dive into a wealth of knowledge at LiveFithub's Articles page. Discover expert insights on fitness, nutrition, mental well-being, and more. Explore a variety of topics tailored to enhance your overall wellness journey. Stay informed and inspired with our curated collection of articles for a healthier lifestyle.");
      
    useEffect(() => {
        const script = document.createElement('script');
        
        script.src = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-7717356982396100";
        script.async = true;
        script.crossOrigin = "anonymous";
        
        document.body.appendChild(script);
        
        return () => {
            document.body.removeChild(script);
        }
        }, []);
    return (
        <div class="container">
        
        <h1 class="d-flex justify-content-center py-3">
            <strong>Workout Articles</strong>
        </h1>

        <a href="/techie-wellness" class="card-title cardLink">
            <Cards image={require('../../images/techieWellness/techie.jpg')} title="Techie Wellness" description="With a proactive approach to health and fitness, tech professionals can navigate the challenges of a sedentary lifestyle and achieve a harmonious balance between work and wellness." date_added="1 Day ago" class="fadein"/>
        </a>  
        
        <a href="/busyProfessional" class="card-title cardLink">
            <Cards image={require('../../images/busyProfessional/desk_treadmill_2.jpg')} title="Busy Professional Zone" description="Welcome to the Busy Professional Zone, where 
            we understand the challenges of maintaining a healthy lifestyle amidst your busy work schedule. LiveFitHub is here to support you on your fitness journey by providing
                efficient and effective workouts tailored to your demanding lifestyle." date_added="2 Weeks ago" class="fadein"/>
        </a>

        <a href="/executiveFitness" class="card-title cardLink">
            <Cards image={require('../../images/executiveFitness/executive_fitness.jpg')} title="Executive Fitness" description="In the fast-paced world of high-demand roles and executive responsibilities, maintaining peak performance requires a holistic approach to fitness that 
            encompasses both physical and mental well-being." date_added="1 Week ago" class="fadein"/>
        </a>

        <a href="/healthcareHeroes" class="card-title cardLink">
            <Cards image={require('../../images/healthcareHeroes/healthcare_heroes.jpg')} title="Healthcare Heroes" description="In the fast-paced world of healthcare, nurses and healthcare professionals are the unsung heroes working tirelessly to ensure the well-being of others. 
            The demanding nature of their jobs, coupled with long hours and high-stress situations, can take a toll on their own health and wellness." date_added="1 Week ago" class="fadein"/>
        </a>
        

        <a href="/ParentalFitness" class="card-title cardLink">
            <Cards image={require('../../images/parentalZone/parent_kids_workout.jpg')} title="Family Wellness" description="Welcome to the Parental Fitness Haven, where we 
            understand the unique challenges of balancing parenthood and personal well-being. LiveFitHub is here to support you on your fitness journey, offering family-friendly 
            workouts and wellness tips tailored for busy parents." date_added="1 Week ago" class="fadein"/>
        </a>

        <a href="/jetSetter" class="card-title cardLink">
            <Cards image={require('../../images/jetSetter/hotel_gym.jpg')} title="Jetsetter Fitness Center" description="Welcome to the Jetsetter Fitness Center, your ultimate 
            destination for staying active and healthy, no matter where your travels take you. LiveFitHub is here to support your fitness journey with on-the-go workouts,
                travel-friendly tips, and wellness resources tailored for jetsetters." date_added="1 Week ago" class="fadein"/>
        </a>

        <a href="/globalFitness" class="card-title cardLink">
            <Cards image={require('../../images/globalFitness/global_fitness.jpg')} title="Global Fitness" description="Our Global 
            Fitness page is your passport to a fitness journey inspired by different cultures and locations worldwide." date_added="1 Week ago" class="fadein"/>
        </a>
        <a href="/elderFitness" class="card-title cardLink">
            <Cards image={require('../../images/seniorWellness/senior_stretching.jpeg')} title="Elder Fitness" description="Welcome to the Senior Wellness Hub, where we celebrate the wisdom and well-being of our senior community. LiveFitHub is
            committed to supporting seniors in their journey toward active and fulfilling lives. Explore tailored workouts, wellness
                resources, and a vibrant community designed for the golden years." date_added="1 Week ago" class="fadein"/>
        </a>  

    </div>
        );
    
}
 
export default Articles;