import "./jetsetter.css"
import React, { useEffect } from "react";


function JetSetter(){

    document.title = 'Fitness on the Fly: Jetsetter Workouts for Global Travelers';
    document.querySelector('meta[name="description"]').setAttribute(
        'content',
        'Explore fitness tips and workout routines tailored for jetsetters and frequent travelers. Discover quick hotel room workouts, nutrition advice for life on the go, and strategies to stay fit and healthy while traveling the world.'
      );


    useEffect(() => {
    const script = document.createElement('script');
    
    script.src = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-7717356982396100";
    script.async = true;
    script.crossOrigin = "anonymous";
    
    document.body.appendChild(script);
    
    return () => {
        document.body.removeChild(script);
    }
    }, []);
    return (  
        <div class="container">
            <div class="container mt-5">
                <h1 class="display-5" ><strong>Jetsetter Fitness Center
                </strong></h1>
                <p>By: Ricky Hernandez</p>
            </div>
            

            <div class="row flex-lg-row-reverse g-5 py-5">
                <div class="">
                    <img src={require('../../../images/jetSetter/hotel_gym.jpg')} class="d-block rounded float-left img-fluid" alt="People in a public gym performing various types of workouts." width="600" height="400" loading="lazy"/>
                </div>
            </div>

            <div class="mb-5">
                <h2><strong>Stay Fit Anywhere in the World</strong></h2>
                <p>Welcome to the Jetsetter Fitness Center, your ultimate destination for staying active and healthy, 
                no matter where your travels take you. LiveFitHub is here to support your fitness journey with on-the-go
                workouts, travel-friendly tips, and wellness resources tailored for jetsetters.</p>
            </div>

            <div class="mb-5">
                <h2><strong>Featured Workouts for Jetsetters</strong></h2>

                <p>Discover workouts designed for those always on the move. From quick hotel room exercises to outdoor routines
                with breathtaking views, our curated workouts ensure you can stay fit while exploring the world.</p>
                
                <div class="pt-4">
                    <div class="row justify-content-start">
                        <h2><strong>Quick Hotel Gym Workouts</strong></h2>

                        <p>Some exercises can be performed with Barbell or Dumbbell</p>
                        <p>Each exercises can be performed for 10-15 repetitions</p>

                        <h2><strong>Full Body Workout 1</strong></h2>
                        <p>1. Squat</p>
                        <p>2. Bench Press</p>
                        <p>3. Bent Over Row</p>
                        <p>4. Romanian Deadlift</p>
                        <p>5. Dips (Bench)</p>
                        <p>(Optional) Treadmill Walking: 10-20 minutes</p>

                        <h2><strong>Full Body Workout 2</strong></h2>
                        <p>Shoulder Press</p>
                        <p>Deadlift</p>
                        <p>Pull Ups</p>
                        <p>Lunges</p>
                        <p>Bicep Curls</p>
                        <p>(Optional) Treadmill Walking: 10-20 minutes</p>

                    </div>
                </div>
            </div>
        

            <div class="mb-5 mt-5">
                <h2><strong>Travel-Friendly Exercise Routines</strong></h2>

                <p>No gym? No problem! Explore our travel-friendly exercise routines that require minimal space or equipment. 
                Whether you're in a hotel room, airport lounge, or a scenic park, we've got you covered.</p>

                <h2><strong>Full Body Workout</strong></h2>
                <p>Body Weight Squat</p>
                <p>Push Ups</p>
                <p>Lunges</p>
                <p>Plank</p>
                <p>Chair Dips</p>
                <p>Treadmill Walking: 10-20 minutes</p>

                <h2><strong>Cardio Workout</strong></h2>
                <p>High Knees</p>
                <p>Mountain Climbers</p>
                <p>Burpees</p>
                <p>Jumping Lunges</p>

            </div>

            
            <div class="mb-5">
                <h2><strong>Wellness Tips for the Jetsetter Lifestyle</strong></h2>

                <div class="mt-5 mb-5">
                <img src={require('../../../images/jetSetter/travel_accessories.jpeg')} class="d-block rounded float-left img-fluid" alt="Fitness accessories for travelers or people on the go." width="400" height="200" loading="lazy"/>
                </div>

                <h2><strong>Essential Travel Gear</strong></h2>
                <ol>
                    <li><strong>Portable Blender</strong></li>
                        <dd>Carry a portable blender for on-the-go smoothies and shakes, ensuring you have access to nutritious options.
                        </dd>
                    <li><strong>Collapsible Water Bottle</strong></li>
                        <dd>Stay hydrated with a collapsible water bottle that you can refill, reducing the need for single-use plastic bottles.
                        </dd>
                    <li><strong>Travel-Friendly Snack Containers</strong></li>
                        <dd>Pack nuts, seeds, dried fruits, or trail mix in small, sealable containers for convenient and healthy snacks.
                        </dd>
                    <li><strong>Resistance Bands</strong></li>
                        <dd>Include resistance bands in your travel gear for quick and effective workouts that can be done anywhere.
                        </dd>
                    <li><strong>Comfortable Walking Shoes</strong></li>
                        <dd>Invest in comfortable and versatile walking shoes for exploring new destinations while staying active.
                        </dd>
                </ol>

                <h2><strong>Nutrition Hacks</strong></h2>
                <ol>
                    <li><strong>Pre-Packaged Healthy Snacks</strong></li>
                        <dd>Carry pre-packaged healthy snacks like whole-grain crackers, nut butter packs, or dried fruit for quick energy.
                        </dd>
                    <li><strong>Protein-Packed Options</strong></li>
                        <dd>Include protein-rich options like jerky, Greek yogurt, or protein bars to maintain satiety and muscle health.
                        </dd>
                    <li><strong>Hydration Tricks</strong></li>
                        <dd>Enhance water with flavor by adding slices of lemon, cucumber, or mint to stay refreshed and encourage hydration.
                        </dd>
                    <li><strong>Resistance Bands</strong></li>
                        <dd>Include resistance bands in your travel gear for quick and effective workouts that can be done anywhere.
                        </dd>
                    <li><strong>Smart Restaurant Choices</strong></li>
                        <dd>Opt for grilled or steamed dishes, choose lean proteins, and include a variety of colorful vegetables when dining out.
                        </dd>
                </ol>
            </div>

            <div class="mb-5">
                <h2><strong>Featured Products for Jetsetters</strong></h2>
                <p>Explore products that cater to your jetsetter lifestyle. From collapsible resistance bands to nutritious snacks that travel well, 
                these items are handpicked to enhance your fitness routine while on the move.</p>
            </div>
        </div>
    );
    }

export default JetSetter;