import "./titlestyles.css"

function Title(){
    
        return (
            <div>
            <div class="row title justify-content-end">

              <div class="col-4">
                <a href="/">
                  <header class="d-flex justify-content-center py-3 liveFitTitle">
                    <strong>LiveFitHub</strong>
                  </header>
                </a>
              </div>

              <div class="col-4">
 
              </div>
            </div>
   
            </div>
            
         );
    
}
 
export default Title;