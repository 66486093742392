import './pricing.css';

function Pricing(){
        return (
          
          <div class="container">
            <div class="pricing-header px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center">
              <h1>Pricing</h1>
            </div>
          <div class="card-deck mb-3 text-center">
            <div class="card card-pricing mb-4 box-shadow">
              <div class="card-header">
                <h4 class="my-0 font-weight-normal">Free Trial</h4>
              </div>
              <div class="card-body">
                <h1 class="card-title pricing-card-title">$0 <small class="text-muted">/ mo</small></h1>
                <ul class="list-unstyled mt-3 mb-4">
                  <li>10 users included</li>
                  <li>2 GB of storage</li>
                  <li>Email support</li>
                  <li>Help center access</li>
                </ul>
                <p><strong>No Credit Card Required</strong></p>
                <a href="/login" type="button" class="btn btn-lg btn-block btn-outline-primary">Sign up for free</a>
              </div>
            </div>
            <div class="card card-pricing mb-4 box-shadow">
              <div class="card-header">
                <h4 class="my-0 font-weight-normal">Pro</h4>
              </div>
              <div class="card-body">
                <h1 class="card-title pricing-card-title">$15 <small class="text-muted">/ mo</small></h1>
                <ul class="list-unstyled mt-3 mb-4">
                  <li>20 users included</li>
                  <li>10 GB of storage</li>
                  <li>Priority email support</li>
                  <li>Help center access</li>
                </ul>
                <button type="button" class="btn btn-lg btn-block btn-primary">Get started</button>
              </div>
            </div>
            <div class="card card-pricing mb-4 box-shadow">
              <div class="card-header">
                <h4 class="my-0 font-weight-normal">Enterprise</h4>
              </div>
              <div class="card-body">
                <h1 class="card-title pricing-card-title">$29 <small class="text-muted">/ mo</small></h1>
                <ul class="list-unstyled mt-3 mb-4">
                  <li>30 users included</li>
                  <li>15 GB of storage</li>
                  <li>Phone and email support</li>
                  <li>Help center access</li>
                </ul>
                <button type="button" class="btn btn-lg btn-block btn-primary">Contact us</button>
              </div>
            </div>
          </div>
    
        
        </div>
         );
    
}
 
export default Pricing;