import "./homestyles.css"
import React, { useEffect } from "react";


function Home()  {
    document.title = 'LiveFithub - Your Gateway to a Healthier and Happier Lifestyle';
    document.querySelector('meta[name="description"]').setAttribute(
        'content',
        'Welcome to LiveFithub, where wellness meets convenience. Explore a world of fitness, nutrition, and mindfulness tailored for your busy life. Embrace a healthier you with our expert tips and personalized solutions. Start your journey to well-being today!');
      
    useEffect(() => {
        const script = document.createElement('script');
      
        script.src = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-7717356982396100";
        script.async = true;
        script.crossOrigin = "anonymous";
      
        document.body.appendChild(script);
      
        return () => {
          document.body.removeChild(script);
        }
      }, []);
          
    return (
        <div className="jumbotron">
            <div class='container col-xxl-8 px-4 py-5'>
                <div class="row flex-lg-row-reverse align-items-center g-5 py-5">
                    <div class="col-10 col-sm-8 col-lg-6">
                    <img src={require('../../images/hero_image.jpg')} class="d-block mx-lg-auto img-fluid hero-image" alt="Bootstrap Themes" width="700" height="500" loading="lazy"/>
                </div>
                <div class="col-lg-6">
                    <h1 class="display-5 fw-bold text-body-emphasis lh-1 mb-3">Fit for Every Lifestyle: Your Personalized Path to Wellness</h1>
                    <p class="lead">LiveFitHub is your go-to destination 
                    for fitness tailored to specific lifestyles. Whether you're a busy professional, 
                    a parent juggling family responsibilities, or a frequent traveler, LiveFitHub
                    provides personalized fitness solutions to seamlessly integrate into your unique lifestyle.</p>
                    
                </div>
                </div>
            </div>
        </div>
        );
    
}
 
export default Home;