import "./busyProfessional.css"
import React, { useEffect } from "react";


function BusyProfessional(){

    document.title = 'Busy Professionals Guide to Health';
    document.querySelector('meta[name="description"]').setAttribute('content', 'Discover practical fitness routines, stress-busting strategies, and time-saving wellness tips designed for busy professionals striving for a healthy work-life balance.');
   
    useEffect(() => {
        const script = document.createElement('script');
        
        script.src = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-7717356982396100";
        script.async = true;
        script.crossOrigin = "anonymous";
        
        document.body.appendChild(script);
        
        return () => {
            document.body.removeChild(script);
        }
        }, []);
    return (  
        <div class="container">
            <div class="mt-5">
                <h1 class="display-5" ><strong>Busy Professional Zone</strong></h1>
                <p>By: Ricky Hernandez</p>
            </div>
            

            <div class="row flex-lg-row-reverse g-5 py-5">
                <div class="">
                    <img src={require('../../../images/busyProfessional/desk_treadmill_2.jpg')} class="d-block rounded float-left img-fluid" alt="person walking on the treadmill under desk when they work-from-home " width="600" height="400" loading="lazy"/>
                </div>
            </div>

            <div class="mb-5">
                <h2><strong>Welcome to Your Fitness Oasis Amidst the Hustle!</strong></h2>
                <p>Welcome to the Busy Professional Zone, where we understand the challenges of
                    maintaining a healthy lifestyle amidst your busy work schedule. LiveFitHub is 
                    here to support you on your fitness journey by providing efficient and effective 
                    workouts tailored to your demanding lifestyle.</p>
            </div>

            <div class="mb-5">
                <h2><strong>Featured Workouts for Busy Professionals</strong></h2>
                
                <p>Maximize your time with our specially curated workouts designed for busy professionals.
                From energizing desk exercises to quick HIIT sessions, we've got you covered. These workouts 
                are tailored to boost your energy, relieve stress, and keep you active, even on your busiest days.</p>

                <h2><strong>2 Day Workout</strong></h2>
                <p><strong>Day 1: Full Body</strong></p>
                <p>1. Squat</p>
                <p>2. Bench Press</p>
                <p>3. Bent Over Row</p>
                <p>4. Romanian Deadlift</p>
                <p>5. Dips</p>
                <p><strong>Day 2: Full Body</strong></p>
                <p>1. Overhead Press</p>
                <p>2. Deadlift</p>
                <p>3. Pull Ups</p>
                <p>4. Lunges</p>
                <p>5. Bicep Curls</p>

                <h2><strong>3 Day Workout</strong></h2>
                <p><strong>Day 1: Chest, Shoulders, Triceps</strong></p>
                <p>Squat</p>
                <p>Bench Press</p>
                <p>Bent Over Row</p>
                <p>Romanian Deadlift</p>
                <p>Dips</p>
                <p><strong>Day 2: Back, Biceps</strong></p>
                <p>1. Overhead Press</p>
                <p>2. Deadlift</p>
                <p>3. Pull Ups</p>
                <p>4. Lunges</p>
                <p>5. Bicep Curls</p>
                <p><strong>Day 3: Legs</strong></p>
                <p>1. Squat</p>
                <p>2. Romanian Deadlift</p>
                <p>3. Leg Press</p>
                <p>4. Leg Extension</p>

            
                <h2><strong>4 Day Workout</strong></h2>
                <p><strong>Day 1: Chest, Triceps</strong></p>
                <p>1. Military Press</p>
                <p>2. Close Grip Bench Press</p>
                <p>3. Cable Flies</p>
                <p>4. Overhead Tricep Extension</p>
                <p><strong>Day 2: Back, Biceps</strong></p>
                <p>1. Overhead Press</p>
                <p>2. Pull Ups</p>
                <p>3. Hammer Curls</p>
                <p>4. Cable Rows</p>
                <p>5. Dumbbell Bicep Curls</p>
                <p><strong>Day 3: Legs</strong></p>
                <p>1. Squat</p>
                <p>2. Romanian Deadlift</p>
                <p>3. Leg Press</p>
                <p>4. Leg Extension</p>
                <p><strong>Day 4: Shoulders</strong></p>
                <p>1. Dumbbell Shoulder Press</p>
                <p>2. Lateral Raises</p>
                <p>3. Shoulder press machine</p>
                <p>4. Rear Delt Flies</p>
            </div>
        

            <div class="mb-5">
                <h2><strong>Desk-Friendly Exercise Routines</strong></h2>

                <div class="mt-5 mb-5">
                    <img src={require('../../../images/busyProfessional/desk_treadmill.jpg')} class="d-block rounded float-left img-fluid" alt="working walking on the desk treadmill when working in their office" width="400" height="200" loading="lazy"/>
                    <img src={require('../../../images/busyProfessional/desk_cartoon.jpg')} class="d-block rounded float-left img-fluid" alt="desk exercises to help people who work at a desk or office" width="400" height="200" loading="lazy"/>
                </div>
                <p>No time for the gym? No problem! Explore our desk-friendly exercise routines that seamlessly 
                integrate into your workday. These exercises focus on improving posture, reducing tension, and 
                promoting overall well-being without the need for additional equipment.</p>
            </div>

            <div class="mb-5">
                <h2><strong>Wellness Tips for the Workplace</strong></h2>
                <div class="mt-5 mb-5">
                    <img src={require('../../../images/busyProfessional/clean_desk.jpeg')} class="d-block rounded float-left img-fluid" alt="clean desk provides a healthy and uncluttered mind." width="400" height="200" loading="lazy"/>
                </div>
                <p>In the image above, notice how a clean and organized desk contributes to a positive work environment. Consider incorporating these wellness essentials into your own workspace:
                    <ul>
                        <li><strong>Ergonomic Seating: </strong>Invest in a comfortable chair that supports good posture during long work hours.</li>
                        <li><strong>Hydration Station: </strong>Keep a reusable water bottle on your desk to stay hydrated throughout the day.</li>
                        <li><strong>Mindfulness Tools: </strong>Place a mindfulness journal or stress-relief toys within reach for moments of relaxation.</li>
                        <li><strong>Greenery: </strong>Consider adding a small plant to your desk for a touch of nature, known to boost mood and productivity.</li>
                        <li><strong>Healthy Snack Stash: </strong>Stock your drawer with nutritious snacks like nuts, dried fruits, or protein bars to fuel your energy.</li>
                    </ul>
                </p>

                <p>Discover practical tips to enhance your wellness at work. From healthy snack ideas to mindfulness
                practices, we provide insights and advice to help you create a wellness-focused environment right at your desk.</p>
            </div>

            <div class="mb-5">
                <h2><strong>Featured Products for Busy Professionals</strong></h2>
                <p>Explore recommended products to enhance your busy professional lifestyle. From ergonomic office chairs to 
                fitness apps that sync with your schedule, these products are created to support your fitness goals within 
                the constraints of a demanding work life.</p>
            </div>

        </div>
    );
}

export default BusyProfessional;