import React, { useEffect } from "react";


function GlobalFitness(){

    document.title = 'Global Fitness: Diverse Workouts for Holistic Wellness';
    document.querySelector('meta[name="description"]').setAttribute(
        'content',
        "Embark on a transformative fitness journey with LiveFitHub's Global Fitness. Explore diverse cultural influences in our workouts, fostering holistic well-being. Join the Global Fitness Challenge for a healthier, vibrant you"
      );

    useEffect(() => {
    const script = document.createElement('script');
    
    script.src = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-7717356982396100";
    script.async = true;
    script.crossOrigin = "anonymous";
    
    document.body.appendChild(script);
    
    return () => {
        document.body.removeChild(script);
    }
    }, []);

    return (  
        <div class="container">
            <div class="container mt-5">
                <h1 class="display-5" ><strong>Global Fitness</strong></h1>
                <p>By: Ricky Hernandez</p>
            </div>
            

            <div class="row flex-lg-row-reverse g-5 py-5">
                <div class="">
                    <img src={require('../../../images/jetSetter/hotel_gym.jpg')} class="d-block rounded float-left img-fluid" alt="People in a public gym performing various types of workouts." width="600" height="400" loading="lazy"/>
                </div>
            </div>

            <div class="mb-5">
                <h2><strong>Welcome to Global Fitness at LiveFitHub!</strong></h2>
                <p>At LiveFitHub, we believe in embracing diversity and celebrating the rich tapestry of wellness practices from around the world. Our Global 
                Fitness page is your passport to a fitness journey inspired by different cultures and locations worldwide. We are committed to promoting a holistic 
                approach to well-being that transcends borders.</p>
            </div>

            <div class="mb-5">
                <h2><strong>Explore the World, Embrace Wellness:</strong></h2>

                <p>Discover fitness resources inspired by diverse cultures, offering you a unique and globally influenced approach to wellness. We invite you to 
                embark on a journey that celebrates the beauty of movement, mindfulness, and healthy living from every corner of the globe.</p>
                
                <div class="pt-4">
                    <div class="row justify-content-start">
                        <h2><strong>Fitness Resources:</strong></h2>

                        <h2><strong>Yoga from India:</strong></h2>
                        <p>Immerse yourself in the ancient practice of yoga, drawing inspiration from India. Access guided sessions that blend traditional 
                        asanas with modern variations, promoting flexibility, balance, and mental well-being.</p>
                        
                        <h2><strong>Capoeira from Brazil</strong></h2>
                        <p>Experience the rhythmic and dynamic martial art form from Brazil, Capoeira. Our Capoeira-inspired workouts integrate dance, acrobatics, 
                        and music for a lively and engaging fitness experience.</p>
                        <h2><strong>Tai Chi from China</strong></h2>
                        <p>Embrace the graceful movements of Tai Chi, originating from China. Enhance your balance, coordination, and overall body awareness through 
                        these gentle yet powerful exercises.</p>
                        <h2><strong>Maori Haka Fitness from New Zealand</strong></h2>
                        <p>Infuse your workouts with the spirit of the Maori Haka from New Zealand. High-energy routines that incorporate elements of this traditional
                         dance form for a unique and empowering fitness experience.</p>
                        

                    </div>
                </div>
            </div>
    
            <div class="mb-5">
                <h2><strong>Wellness Without Borders</strong></h2>
                <p>At LiveFitHub, we celebrate the beauty of cultural diversity and believe that fitness knows no boundaries. Our Global Fitness page is your gateway to a
                 world of wellness, where every workout is a celebration of the rich and varied traditions that make our global community unique.</p>

                <h2><strong>Start Your Global Fitness Journey Today!</strong></h2>
                <p>Unleash the power of diverse wellness practices. Join our Global Fitness community and let the world inspire your path to a healthier, more vibrant you!</p>
            </div>
        </div>
    );
    }

export default GlobalFitness;