import "./footer.css"

function Footer(){
    return ( 
            
        <div class="container">
            <footer class="d-flex flex-wrap justify-content-between align-items-center py-3 my-4 border-top">

                <div>
                    <a href="/about" class="nav-link" >About Us</a>
                </div>
                <div class="">
                    <span class="text-body-secondary">© 2023 LiveFitHub</span>
                </div>
                

                {/* <div class="logos">
                    <ul class="nav col-md-4 justify-content-end list-unstyled d-flex">
                    <li class="ms-3">
                        <a target="_blank" href="https://github.com/ricardoh19">
                            <img src={require('../../images/IG.png')} width="30" height="30" alt="logo"/>
                        </a>
                    </li>
                    </ul>
                </div>
                */}
            </footer>
        </div>
        );
}

 
export default Footer;