import "./cards.css"

function Cards(props){
    return ( 
            
        <div class="mb-3 border">
        <div class="row no-gutters">
          <div class="col-md-4 fadein">
          <img src={props.image} class="card-img" alt="passed in" loading="lazy" height="200" />

          </div>
          <div class="col-md-8">
            <div class="card-body fadein p-3">
              <p class="card-text"><small class="text-muted">{props.date_added}</small></p>
              <h5 class="card-title "><strong>{props.title}</strong></h5>
              <p class="card-text">{props.description}</p>
            </div>
          </div>
        </div>
      </div>
        );
}

export default Cards;