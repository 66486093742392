import "./navstyles.css"
import React, { useRef } from "react";
import { FaBars, FaTimes } from "react-icons/fa";



function Navigate(){

  const navRef = useRef();

	const showNavbar = () => {
		navRef.current.classList.toggle(
			"responsive_nav"
		);
	};

  return (
      <div class="border d-flex justify-content-center">
        <header class="navHeader">
            <nav className="header-nav" ref={navRef}>
              <a href="/">Home</a>
              <a href="/articles">Workout Articles</a>
              <a href="/create-workout">Create Your Own Workout</a>
              <button
                className="nav-btn nav-close-btn"
                onClick={showNavbar}>
                <FaTimes />
              </button>
            </nav>
          <button
              className="nav-btn"
              onClick={showNavbar}>
              <FaBars />
            </button>
        </header>

      
    </div>
    );
    
}
 
export default Navigate;