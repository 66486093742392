import ReactDOM from 'react-dom'
import React, { useRef, useState,useEffect } from "react";
import "./createWorkout.css"
import WorkoutResult from '../WorkoutResult/workoutResult';




function CreateWorkout(){

    const age = useRef(null);
    const weight = useRef(null);
    const height = useRef(null);
    const gender = useRef(null);
    const goal = useRef(null);

    const renderElement = () => {

        // const root = ReactDOM.createRoot(document.getElementById('root'));
        const rootElement = document.getElementById('root');

        const element = <WorkoutResult
        age={age.current.value} 
        weight={weight.current.value} 
        height={height.current.value} 
        gender={gender.current.value} 
        goal={goal.current.value} 
        daysExercising={selectedOption}
        class="fadein"/>;

        ReactDOM.render(element, rootElement);
    
    };

    const [selectedOption, setSelectedOption] = useState('');

    const onValueChange = (event) => {
        setSelectedOption(event.target.value)
    };

    useEffect(() => {
        const script = document.createElement('script');
        
        script.src = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-7717356982396100";
        script.async = true;
        script.crossOrigin = "anonymous";
        
        document.body.appendChild(script);
        
        return () => {
            document.body.removeChild(script);
        }
        }, []);
    
    

    return (  
        <div className="container pb-5">
            <div class="pt-4">
                <form onSubmit={renderElement}class="needs-validation" novalidate="">
                    
                    <div class="mb-3">
                        <label for="age">Age</label>
                        <div >
                            <input type="text" class="form-control" id="age" ref={age} placeholder="age" required=""/>
                            <div class="invalid-feedback" >
                                Your age is required.
                            </div>
                        </div>
                    </div>

                    <div class="mb-3">
                        <label for="weight">Weight (lbs)</label>
                        <div>
                        
                        <input type="text" class="form-control" ref={weight} id="weight" placeholder="Weight" required=""/>
                        <div class="invalid-feedback" >
                            Your weight is required.
                        </div>
                        </div>
                    </div>

                    <div class="mb-3">
                        <label for="height">Height (inches)</label>
                        <div>
                        
                        <input type="text" class="form-control" ref={height} id="height" placeholder="Height" required=""/>
                        <div class="invalid-feedback" >
                            Your height is required.
                        </div>
                        </div>
                    </div>


                    <div class="row">
                        <div class="col-md-5 mb-3">
                        <label for="gender">Gender</label>
                        <select class="custom-select d-block w-100" ref={gender} id="gender" required="">
                            <option value="">Choose...</option>
                            <option>Man</option>
                            <option>Woman</option>
                            <option>Other</option>
                        </select>
                        <div class="invalid-feedback">
                            Please select a gender.
                        </div>
                        </div>


                        <div class="col-md-4 mb-3">
                        <label for="goal">Goal</label>
                        <select class="custom-select d-block w-100" ref={goal} id="goal" required="">
                            <option value="">Choose...</option>
                            <option>Lose weight</option>
                            <option>Maintain weight</option>
                            <option>Gain weight</option>
                        </select>
                        <div class="invalid-feedback">
                            Please provide a valid goal.
                        </div>
                        </div>
                    </div>


                    <hr class="mb-4"/>
                    
                   

                    <h4 class="mb-3">How many days are you exercising?</h4>

                    <div class="d-block my-3">
                        <div class="custom-control custom-radio">
                            <input id="one" value="1" checked={selectedOption === "1"}
                            onChange={onValueChange} type="radio" class="custom-control-input" required=""/>
                            <label class="custom-control-label mx-2">1</label>
                        </div>
                        <div class="custom-control custom-radio">
                            <input id="two" type="radio" value="2" checked={selectedOption === "2"}
                            onChange={onValueChange} class="custom-control-input" required=""/>
                            <label class="custom-control-label mx-2">2</label>
                        </div>
                        <div class="custom-control custom-radio">
                            <input id="three" type="radio" value="3" checked={selectedOption === "3"}
                            onChange={onValueChange} class="custom-control-input" required=""/>
                            <label class="custom-control-label mx-2" >3</label>
                        </div>
                        <div class="custom-control custom-radio">
                        <input id="four" type="radio" value="4" checked={selectedOption === "4"}
                        onChange={onValueChange} class="custom-control-input" required=""/>
                        <label class="custom-control-label mx-2">4</label>
                        </div>
                        <div class="custom-control custom-radio">
                        <input id="five" type="radio" value="5" checked={selectedOption === "5"}
                        onChange={onValueChange} class="custom-control-input" required=""/>
                        <label class="custom-control-label mx-2" >5</label>
                        </div>
                        <div class="custom-control custom-radio">
                        <input id="six" type="radio" value="6" checked={selectedOption === "6"}
                        onChange={onValueChange} class="custom-control-input" required=""/>
                        <label class="custom-control-label mx-2" >6</label>
                        </div>
                        <div class="custom-control custom-radio">
                        <input id="seven" type="radio" value="7" checked={selectedOption === "7"}
                        onChange={onValueChange} class="custom-control-input" required=""/>
                        <label class="custom-control-label mx-2" >7</label>
                        </div>
                    </div>

                    <hr class="mb-4"/>
                    <button class="btn btn-primary btn-lg btn-block" type="submit">Create workout</button>
                </form>
            </div>    
        </div>
    );
    }

export default CreateWorkout;