import Title from "../Title/title";
import Navigate from "../Navbar/navigate";
import Footer from "../Footer/footer";

function WorkoutResult(props){

  const age = props.age;
  const weight = props.weight;
  const height = props.height;
  const gender = props.gender
  const goal = props.goal;
  const daysExercising = props.daysExercising;
  let caloriesResult = 0;

  // calculate calorie result
  if (gender === "Man"){
    caloriesResult = parseInt(66.47 + (6.24 * parseFloat(weight)) + (12.7 * parseFloat(height)) - (6.755 * parseFloat(age)))
  }
  else{
    caloriesResult = 655.1 + (4.35 * parseFloat(weight)) + (4.7 * parseFloat(height)) - (4.7 * parseFloat(age))
  }

  if (goal === "Maintain"){
    caloriesResult *= 1.5
  }
    caloriesResult *= 1.5
  if (goal === "Lose"){
    caloriesResult *= 1.15
  }
  if (goal === "Gain"){
    caloriesResult *= 1.65
  }
      
  document.title = 'LiveFithub Personalized Workouts: Tailored Fitness Experiences Just for You';
  document.querySelector('meta[name="description"]').setAttribute(
      'content',
      "Unleash your fitness potential with LiveFithub's Personalized Workout for Users. Experience tailored exercise routines designed to meet your unique goals and preferences. Elevate your fitness journey with expert guidance, personalized plans, and a pathway to a healthier, fitter you. Start your personalized workout today!");
    

          

  return ( 
    <>
      <Title />
      <Navigate />
      <div class="container my-3 " >
        <div >
        
          <div class="border p-2 m-4">
            <div >
              <h2 ><strong>Calorie Intake Result</strong></h2>
              <p>The results show a number of daily calorie estimates that can be used as a guideline for how many calories to consume each day at the chosen rate.</p>
              <p>Result: <strong>{caloriesResult}</strong></p>
            </div>
          </div>

          
            <div class="border p-2 m-4">
              
              {daysExercising === '2' ? (
                <div>
                  <h2>Workout 1</h2>
                  <p >1. Squat</p>
                  <p >2. Bench Press</p>
                  <p >3. Bent Over Row</p>
                  <p >4. Romanian Deadlift</p>
                  <p >5. Dips</p>
                
                  <h2>Workout 2</h2>
                  <p >1. Overhead Press</p>
                  <p >2. Deadlifts </p>
                  <p >3. Pullups</p>
                  <p >4. Lunges</p>
                  <p >5. Bicep Curls</p>
                </div>
                

              ) : null}
              {daysExercising === '3' ? (

                <div>
                  <h2>Workout 1</h2>
                  <p >1. Bench Press</p>
                  <p >2. Incline Dumbbell Press</p>
                  <p >3. Cable Fly</p>
                  <p >4. Dips</p>
                  <p >5. Lateral Raises</p>
                  <p >6. Tricep Extensions</p>
                
                  <h2>Workout 2</h2>
                  <p >1. Pull Ups</p>
                  <p >2. Hammer Curls</p>
                  <p >3. Cable Curls</p>
                  <p >4. Cable Rows</p>
                  <p >5. Dumbbell Curls</p>

                  <h2>Workout 3</h2>
                  <p >1. Squat Press</p>
                  <p >2. RDL</p>
                  <p >3. Leg Press</p>
                  <p >4. Leg Extension</p>
                </div>

              ) : null}
              {daysExercising === '4' ? (

                <div>
                  <h2>Workout 1</h2>
                  <p >1. Military Press</p>
                  <p >2. Close Grip Bench Press</p>
                  <p >3. Cable Flies</p>
                  <p >4. Romanian Overhead Tricep Extension</p>
                
                  <h2>Workout 2</h2>
                  <p >1. Pullups</p>
                  <p >2. Hammer Curls</p>
                  <p >3. Cable Rows</p>
                  <p >4. Dumbbell Curls</p>

                  <h2>Workout 3</h2>
                  <p >1. Squat Press</p>
                  <p >2. RDL</p>
                  <p >3. Leg Press</p>
                  <p >4. Leg Extension</p>

                  <h2>Workout 4</h2>
                  <p >1. Cable Curls Press</p>
                  <p >2. Tricep Extension </p>
                  <p >3. Dumbbell Shoulder Press</p>
                  <p >4. Preacher Curl Machine</p>
                  <p >5. Shoulder Press Machine</p>
                  <p >5. Rope Bicep Curls</p>
                </div>

              ) : null}
              {daysExercising === '5' ? (

                <div>
                  <h2>Workout 1</h2>
                  <p >1. Bench Press</p>
                  <p >2. Incline Dumbbell Press</p>
                  <p >3. Cable Fly</p>
                  <p >4. Dips</p>
                  
                
                  <h2>Workout 2</h2>
                  <p >1. Pull Ups</p>
                  <p >2. Cable Rows</p>
                  <p >3. Cable Pullovers</p>

                  <h2>Workout 3</h2>
                  <p >1. Cable Curls</p>
                  <p >2. Rope Bicep Curls</p>
                  <p >3. Tricep Extension</p>
                  <p >4. Tricep Kickback</p>

                  <h2>Workout 4</h2>
                  <p >1. Squat Press</p>
                  <p >2. RDL</p>
                  <p >3. Leg Press</p>
                  <p >4. Leg Extension</p>

                  <h2>Workout 5</h2>
                  <p >1. Shoulder Press Machine</p>
                  <p >2. Lateral Raises</p>
                  <p >3. Rear Delt Flies</p>                
                </div>

              ) : null}
              {daysExercising === '6' ? (

                <div>
                  <h2>Workout 1</h2>
                  <p >1. Bench Press</p>
                  <p >2. Incline Dumbbell Press</p>
                  <p >3. Cable Fly</p>
                  <p >4. Dips</p>
                  <p >5. Tricep Extension</p>
                  
                
                  <h2>Workout 2</h2>
                  <p >1. Pull Ups</p>
                  <p >2. Hammer Curls</p>
                  <p >3. Cable Rows</p>

                  <h2>Workout 3</h2>
                  <p >1. Squat Press</p>
                  <p >2. RDL</p>
                  <p >3. Leg Press</p>
                  <p >4. Leg Extension</p>

                  <h2>Workout 4</h2>
                  <p >1. Lateral Raises</p>
                  <p >2. Military Press</p>
                  <p >3. Close Grip Bench Press</p>  
                  <p >4. Cable Flies</p>  
                  <p >5. Overhead Tricep Extension</p>  
                

                  <h2>Workout 5</h2>
                  <p >1. Cable Curls</p>
                  <p >2. Dumbbell Curls</p>
                  <p >3. Pull Downs</p>  
                  <p >4. Seated Cable Row</p>  
                  <p >5. Hammer Curls</p>  

                  <h2>Workout 6</h2>
                  <p >1. Deadlift</p>
                  <p >2. Goblet Squat</p>
                  <p >3. Hamstring Curls</p>  
                  <p >4. Prisoner Back Extension</p>  
                  <p >5. Calf Raises</p>  
                </div>

              ) : null}
              {daysExercising === '7' ? (

                <div>
                  <h2>Workout 1</h2>
                  <p >1. Bench Press</p>
                  <p >2. Incline Dumbbell Press</p>
                  <p >3. Cable Fly</p>
                  <p >4. Dips</p>
                  <p >5. Tricep Extension</p>
                  
                
                  <h2>Workout 2</h2>
                  <p >1. Pull Ups</p>
                  <p >2. Hammer Curls</p>
                  <p >3. Cable Rows</p>

                  <h2>Workout 3</h2>
                  <p >1. Squat Press</p>
                  <p >2. RDL</p>
                  <p >3. Leg Press</p>
                  <p >4. Leg Extension</p>

                  <h2>Workout 4</h2>
                  <p >1. Lateral Raises</p>
                  <p >2. Military Press</p>
                  <p >3. Close Grip Bench Press</p>  
                  <p >4. Cable Flies</p>  
                  <p >5. Overhead Tricep Extension</p>  
                

                  <h2>Workout 5</h2>
                  <p >1. Cable Curls</p>
                  <p >2. Dumbbell Curls</p>
                  <p >3. Pull Downs</p>  
                  <p >4. Seated Cable Row</p>  
                  <p >5. Hammer Curls</p>  

                  <h2>Workout 6</h2>
                  <p >1. Deadlift</p>
                  <p >2. Goblet Squat</p>
                  <p >3. Hamstring Curls</p>  
                  <p >4. Prisoner Back Extension</p>  
                  <p >5. Calf Raises</p>  
                  
                  <h2>Workout 7</h2>
                  <p >Light or Heavy Cardio</p>
                  
                </div>

              ) : null}
            </div>
         

      </div>
    </div>
    <Footer />
    </>
  );
}

export default WorkoutResult;