import "./about.css"

function About(){
    return ( 
        <div class="container mt-4">
            <h1 class="d-flex justify-content-center py-3">
                <strong>About Us</strong>
            </h1>
            <div class="mx-4">
                <p>Welcome to LiveFitHub, your ultimate destination for holistic well-being and fitness! At LiveFitHub, we believe in empowering individuals to 
                lead healthier, happier lives by providing a comprehensive platform that caters to all aspects of wellness.</p>

                <p>Our mission is to inspire and support you on your journey to optimal health, offering a diverse range of resources, expert advice, and 
                engaging content. Whether you're a seasoned fitness enthusiast or just starting on your wellness path, LiveFitHub is your go-to community 
                for personalized fitness routines, nutrition guidance, mental health support, and much more.</p>

                <p>We understand that everyone's wellness journey is unique, so we've curated a team of passionate experts from various fields to bring you
                 well-rounded, evidence-based information. From certified fitness trainers and nutritionists to mindfulness experts, our contributors are 
                 dedicated to helping you achieve your goals and live your best life.</p>

                 <p>At LiveFitHub, we foster a positive and inclusive community where members can connect, share experiences, and motivate each other. Join us 
                as we strive to create a supportive environment that encourages a balanced and sustainable approach to health and fitness.</p>

                <p>Whether you're looking for workout plans, healthy recipes, mindfulness practices, or simply a supportive community to share your achievements and 
                challenges, LiveFitHub is here to guide you every step of the way. Let's embark on this journey together and make wellness a lifestyle that lasts. 
                Welcome to LiveFitHub—where your well-being comes first!</p>
             </div>   
      </div>
    );
}

 
export default About;