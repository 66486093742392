import React, { useEffect } from "react";

function ElderFitness(){

    document.title = 'Healthy Aging: Senior Wellness Tips and Strategies';
    document.querySelector('meta[name="description"]').setAttribute(
        'content',
        'Discover holistic wellness strategies for seniors to thrive in their golden years. Explore gentle fitness exercises, nutritional guidance, mental health practices, and tips for maintaining a healthy and active lifestyle as a senior.'
      );

    useEffect(() => {
    const script = document.createElement('script');
    
    script.src = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-7717356982396100";
    script.async = true;
    script.crossOrigin = "anonymous";
    
    document.body.appendChild(script);
    
    return () => {
        document.body.removeChild(script);
    }
    }, []);
    return (  
        <div class="container">
            <div class="container mt-5">
                <h1 class="display-5" ><strong>Senior Wellness Hub</strong></h1>
                <p>By: Ricky Hernandez</p>
            </div>
            

            <div class="row flex-lg-row-reverse g-5 py-5">
                <div class="">
                    <img src={require('../../../images/seniorWellness/senior_laughing.jpeg')} class="d-block rounded float-left img-fluid" alt="Group of senior ladies enjoying their time after a workout." width="600" height="400" loading="lazy"/>
                </div>
            </div>

            <div class="mb-5">
                <h2><strong>Thriving in Every Chapter of Life</strong></h2>
                <p>Welcome to the Senior Wellness Hub, where we celebrate the wisdom and well-being of our senior community. LiveFitHub is
                 committed to supporting seniors in their journey toward active and fulfilling lives. Explore tailored workouts, wellness
                  resources, and a vibrant community designed for the golden years.</p>
            </div>

            <div class="mb-5">
                <h2><strong>Featured Wellness Programs for Seniors</strong></h2>
                
                <div class="mt-5 mb-5">
                    <img src={require('../../../images/seniorWellness/senior_yoga.jpeg')} class="d-block rounded float-left img-fluid" alt="Group of seniors performing yoga." width="400" height="200" loading="lazy"/>
                </div>

                <p>Discover wellness programs crafted for seniors, focusing on gentle exercises, balance training, and social 
                activities. Our curated programs ensure that every senior can find activities that promote strength, flexibility,
                 and community.</p>

            </div>
        

            <div class="mb-5">
                <h2><strong>Gentle Exercise Routines</strong></h2>

                <div class="mt-5 mb-5">
                    <img src={require('../../../images/seniorWellness/senior_stretching.jpeg')} class="d-block rounded float-left img-fluid" alt="Elderly lady stretching using resistance bands." width="400" height="200" loading="lazy"/>
                </div>
                
                <p>Prioritize your well-being with gentle exercise routines specifically designed for seniors. From seated chair exercises to balance-enhancing activities,
                 our routines cater to various fitness levels while considering individual needs.</p>

                 <div class="container">
                    <p><strong>Warm-Up: Gentle Joint Mobilization (5 minutes)</strong></p>
                    <ol>
                        <li><strong>Neck Circles</strong></li>
                            <dd>Slowly rotate the neck in clockwise and counterclockwise circles. Repeat for 1 minute</dd>
                        <li><strong>Shoulder Rolls</strong></li>
                            <dd>Lift the shoulders up towards the ears, then roll them back and down. Repeat for 1 minute.
                            </dd>
                        <li><strong>Wrist Flexor and Extensor Stretches</strong></li>
                            <dd>Gently stretch and flex the wrists, holding each position for a few seconds. Repeat for 1 minute.
                            </dd>
                        <li><strong>Ankle Circles</strong></li>
                            <dd>Lift one foot and rotate the ankle in circles, then switch to the other foot. Repeat for 1 minute.
                            </dd>
                    </ol>

                    <p><strong>Main Routine: Enhancing Flexibility, Balance, and Strength (15 minutes)</strong></p>
                    <ol>
                        <li><strong>Seated Marching</strong></li>
                            <dd>Sit tall in a chair and lift one knee at a time, mimicking a marching motion. Perform for 2 minutes.
                            </dd>
                        <li><strong>Leg Extensions</strong></li>
                            <dd>While seated, extend one leg straight in front and hold for a few seconds. Lower the leg and switch sides. Repeat for 2 minutes.
                            </dd>
                        <li><strong>Chair Squats</strong></li>
                        <dd>Stand behind a sturdy chair, lower into a seated position, then stand back up. Hold onto the chair for support. Repeat for 2 minutes.
                        </dd>
                        <li><strong>Heel Raises</strong></li>
                        <dd>Stand or sit and lift your heels off the ground, then lower them back down. Repeat for 2 minutes.
                        </dd>
                        <li><strong>Side Leg Raises</strong></li>
                        <dd>Hold onto a sturdy surface, like a kitchen counter, and lift one leg to the side. Lower it back down and switch sides. Repeat for 2 minutes.
                        </dd>
                    </ol>

                    <p><strong>Cool Down: Stretching and Relaxation (5 minutes)</strong></p>
                    <ol>
                        <li><strong>Neck Stretch</strong></li>
                            <dd>Gently tilt your head to one side, feeling a stretch along the neck. Hold for 30 seconds on each side.
                            </dd>
                        <li><strong>Shoulder Stretch</strong></li>
                            <dd>Bring one arm across your chest, using the opposite hand to gently press on the elbow. Hold for 30 seconds on each side.
                            </dd>
                        <li><strong>Seated Forward Fold</strong></li>
                            <dd>Sit tall and hinge at the hips to reach towards your toes. Hold for 30 seconds.
                            </dd>
                        <li><strong>Calf Stretch</strong></li>
                            <dd>Stand facing a wall, place one foot behind you with the heel on the ground, and lean forward. Hold for 30 seconds on each side.
                            </dd>
                        <li><strong>Deep Breathing and Relaxation</strong></li>
                            <dd>Sit comfortably, close your eyes, and focus on slow, deep breaths. Inhale for a count of four, hold for four, exhale for four. Repeat for 2 minutes.
                            </dd>
                    </ol>
                </div>

                <p>This gentle exercise routine can be done daily to promote flexibility, balance, and overall well-being for seniors. Always perform exercises at a comfortable
                 pace, and if any movement causes discomfort, it's advisable to skip or modify that particular exercise.</p>

            </div>

            <div class="mb-5">
                <h2><strong>Nutrition and Health Tips</strong></h2>

                <ol>
                    <li><strong>Balanced Diet</strong></li>
                        <dd>Aim for a well-balanced diet that includes a variety of fruits, vegetables, lean proteins, whole grains, and low-fat dairy or dairy alternatives.
                        </dd>
                    <li><strong>Hydration</strong></li>
                        <dd>Stay well-hydrated by drinking plenty of water throughout the day. Dehydration can contribute to fatigue and affect overall health.
                        </dd>
                    <li><strong>Calcium and Vitamin D</strong></li>
                        <dd>Ensure an adequate intake of calcium and vitamin D to support bone health. Include dairy products, fortified cereals, and leafy greens in your diet.
                        </dd>
                    <li><strong>Fiber-Rich Foods</strong></li>
                        <dd>Include fiber-rich foods like whole grains, fruits, vegetables, and legumes to promote digestive health and prevent constipation.
                        </dd>
                    <li><strong>Healthy Fats</strong></li>
                        <dd>Choose sources of healthy fats, such as avocados, nuts, seeds, and olive oil, to support heart health.
                        </dd>
                </ol>
            
            </div>

            <div class="mb-5">
                <h2><strong>Wellness Socials and Events</strong></h2>

                <p>Join wellness social events designed to foster connection and community. Whether it's a group walk, 
                a virtual tea gathering, or a fitness class, our events provide opportunities for seniors to socialize and stay
                 active.</p>
            </div>

            <div class="mb-5">
                <h2><strong>Senior-Friendly Products</strong></h2>
                <p>Explore products that enhance senior wellness. From accessible fitness equipment to comfortable seating
                 options, these items are chosen to support a senior-friendly lifestyle.</p>
            </div>

        </div>
    );
}

export default ElderFitness;